<template>
  <div>
    <v-row class="px-5 pt-5">
      <v-col cols="4" sm="12" md="12" lg="4" class="px-0 py-0">
        <!-- Frequency Type -->
        <v-autocomplete persistent-placeholder outlined class="ma-0 pa-0" v-model="frequencyType" dense :items="frequencyTypeList" label="Occurrence"
          item-text="frequencyType" item-value="value" @change="onChangeFrequency(frequencyType)">
        </v-autocomplete>
      </v-col>
      <!-- Start Date and end Date flag -->
      <v-col v-if="frequencyType == 3" cols="8" sm="12" md="12" lg="6" class="px-5 py-0 ">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6" class="px-1 pt-1">
            <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="primarytext" persistent-placeholder :value="computedDateFormattedMomentjs" dense outlined label="Start Date"
                  class="py-2" readonly v-bind="attrs" v-on="on" @click:clear="startDate = null"></v-text-field>
              </template>
              <v-date-picker :max="maxStartDate" v-model="startDate" @change="menu1 = false; setEndDate()"></v-date-picker>
            </v-menu>
          </v-col>
          <!-- ENd Date -->
          <v-col cols="12" sm="12" md="12" lg="6" class="px-1 pt-1">
            <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="primarytext" persistent-placeholder :value="computedEndDateFormattedMomentjs" dense outlined label="End Date"
                  class="py-2" readonly v-bind="attrs" v-on="on" @click:clear="endDate = null"></v-text-field>
              </template>
              <v-date-picker :max="maxEndDate" :min="minEndDate" v-model="endDate" @change="menu2 = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="frequencyType == 3" cols="8" sm="12" md="12" lg="2" class="px-5 py-0 ">
        <v-btn outlined class="pt-1" color="primarytext" @click="onClickApply()">
          Apply
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import EncryptUtility from "../utility/js/encrypt-utility";
import moment from "moment";
export default {
  data() {
    return {
      startDate: "",
      page: 0,
      endDate: "",
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      frequencyType: 0,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      totalUserRecord: 0,
      pageCount: 0,
      menu1: false,
      menu2: false,
      frequencyTypeList: [
        { frequencyType: "Daily", value: 0 },
        { frequencyType: "Weekly", value: 1 },
        { frequencyType: "Monthly", value: 2 },
        { frequencyType: "Custom", value: 3 },
      ],
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.startDate
        ? moment(this.startDate).format("MM/DD/YYYY")
        : moment(new Date().getTime() - 86400000 * 60).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.endDate
        ? moment(this.endDate).format("MM/DD/YYYY")
        : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  async mounted() {

  },
  methods: {
    async onChangeFrequency(frequency) {

      this.frequencyType = frequency;
      if (frequency != 3) {
        this.page = 1;
        this.$emit('filterData', this.page, this.frequencyType, this.startDate, this.endDate)
      } else {
        this.pageCount = 0;
        this.totalUserRecord = 0;
        this.userActivityList = [];
      }
    },

    onClickApply() {
      let tempStartDate = new Date().getTime() - 86400000 * 60
      this.startDate = this.startDate ? this.startDate : new Date(tempStartDate).toISOString().substr(0, 10)
      this.endDate = this.endDate ? this.endDate : new Date().toISOString().substr(0, 10)
      this.$emit('filterData', 1, this.frequencyType, this.startDate, this.endDate)
    },

    // For End Date
    setEndDate() {
      let minEndDate = new Date(this.startDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000)
        .toISOString()
        .substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0)
        .toISOString()
        .substr(0, 10);
    },
  },
};
</script>