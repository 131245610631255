import breadcrumbComp from "../common/breadcrumb-comp";
import FilterComp from "./filter-comp";
import FilterTableComp from "../common/filter-comp.vue";
import AddFavourite from "../utility/js/addFav";
import EncryptUtility from "../utility/js/encrypt-utility";
import Utility from "../../shared/utility";
import activityLoggerService from "./activity-logger-service";
import Chart from "chart.js/auto";
import moment from "moment";
import { bus } from "../../main";
export default {
  data() {
    return {
      route: "",
      actionList: [],
      favouriteList: [],
      userActivityList: [],
      exceptionActivityList: [],
      uniqueStatus:[],
      loginActivityList: [],
      uniqueEmail:[],
      loginActivityListTemp:[],
      userActivityListTemp:[],
      arrayUserData:[],   
      uniqueActivity:[],
      uniqueName:[],
      arrayData:[],
      totalLoginRecords:0,
      frequencyType: "",
      tempFrequencyType:0,
      searchUser:"",
      searchActivity:"",
      mainPage: "",
      pageUrl: "",
      subPage: "",
      showAdd: "",
      filterType: "",
      startDate: "",
      endDate: "",
      tabName: "login",
      backSlash: true,
      dialogBoxHeader: "",
      displayMessage: "",
      showCanvas: true,
      options: {},
      page: 1,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      totalUserRecord: 0,
      pageCount: 0,
      menu1: false,
      menu2: false,
      showDialogDetailsBox: false,
      exceptionHeader: [
        {
          text: "Description",
          align: "Start",
          value: "description",
          class: "primary customwhite--text",
        },
        {
          text: "Class Name",
          value: "class_name",
          class: "primary customwhite--text",
        },

        {
          text: "Method Name",
          value: "method_name",
          class: "primary customwhite--text",
        },

        {
          text: "Stack Trace",
          value: "stack_trace",
          class: "primary customwhite--text",
        },
        {
          text: "Warning Type",
          value: "warning_type",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "CreatedDate",
          class: "primary customwhite--text",
        },
      ],
      userActivityHeader: [
        {
          text: "Name",
          align: "Start",
          value: "name",
          class: "primary customwhite--text",
        },
        {
          text: "Action",
          value: "action",
          class: "primary customwhite--text",
        },

        {
          text: "Created Date",
          value: "CreatedDate",
          class: "primary customwhite--text",
        },
      ],
      loginHeader: [
        {
          text: "Email",
          align: "Start",
          value: "Email",
          class: "primary customwhite--text",
        },
        {
          text: "Application Code",
          value: "AppCode",
          class: "primary customwhite--text",
        },
        {
          text: "System Info",
          value: "SystemInfo",
          class: "primary customwhite--text",
        },
        {
          text: "Login Success",
          value: "Success",
          class: "primary customwhite--text",
        },
        {
          text: "Error Details",
          value: "Error",
          class: "primary customwhite--text",
        },

        {
          text: "Created Date",
          value: "CreatedDate",
          class: "primary customwhite--text",
        },
      ],

      chartData2: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: "Number Of Users",
              data: [],
              backgroundColor: "#005ba8",
              borderColor: "#f89554",
              borderWidth: 1,
            },
          ],
          hoverOffset: 4,
        },
        options: {
          
          responsive: true,
          lineTension: 1,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          onClick: event => {
            const points = this.chartInstance?.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
            const firstPoint = points[0];
            const label = this.chartInstance?.data?.labels[firstPoint?.index];
            if(label){
              this.frequencyType=3
            if(!label.includes("-") && label.includes("/")){
            let sDate=new Date(label)
            this.endDate = moment(sDate.getTime() + 86400000).format("MM/DD/YYYY");
            this.startDate = label;
            this.postLoginActivity(1, this.frequencyType,false)
          }
          else if(label.includes("-")){
            this.endDate = label?.split(" - ")[1].trim();
            this.startDate = label?.split(" - ")[0].trim();
            this.postLoginActivity(1, this.frequencyType,false)
          }
          else if(!label.includes("/")){
           let month=label.split(" ")[0];
           let year=label.split(" ")[1];
           let monthDetails=Utility.getMonthDetails(month,year)
           this.endDate = monthDetails[0].endDate+"/"+year
           this.startDate = monthDetails[0].startDate+"/"+year
           this.postLoginActivity(1, this.frequencyType,false)
          }
        }
      }
          
        },
      },
      chartInstance: null,
      ctxChart: null,
      //  let ctx2 = document.getElementById("chart2"),
      //  new Chart(ctx2, this.chartData2),
    };
  },
  computed: {},
  async mounted() {
   
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data?.actionList;
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.postLoginActivity(1, 0,true);
  },
  created() {},

 
  methods: {
    getTab(name) {
      bus.$emit("clearFilter", true);
      this.searchUser="";
      this.searchActivity="";
      this.tabName = name;
      if (name == "execption") {
        this.postExpectionActivity(1, 0);
      } else if (name == "user") {
        this.postUserActivity(1, 0);
      } else if (name == "login") {
        this.postLoginActivity(1, 0,true);
      }
    },

    async postUserActivity(PageNumber, frequencyType) {
      
      this.pageCount = 0;
      this.totalUserRecord = 0;
      
      this.userActivityList = [];
      if (frequencyType != 3) {
        this.endDate = "";
        this.startDate = "";
      }

      let obj = {
        UserId: this.userId,
        FrequencyType: frequencyType,
        StartDate: this.startDate,
        EndDate: this.endDate,
        PageNumber: PageNumber,
      };
      let trackerJSON = { trackerJSON: JSON.stringify(obj) };
      let getUserData = await activityLoggerService.postUserActivity("post", trackerJSON);
      this.userActivityList = getUserData?.Resultset;
      this.userActivityListTemp=this.userActivityList;
      this.uniqueName = [...new Map(this.userActivityListTemp.map((item) => [item["name"], item.name])).values()];
      this.uniqueActivity = [...new Map(this.userActivityListTemp.map((item) => [item["action"], item.action])).values()];
      this.arrayUserData=[
        {id:1,label:"Name",array:this.uniqueName },
        {id:2,label:"Action",array:this.uniqueActivity },
      ]
      this.userActivityList?.forEach((element) => {
        element.CreatedDate = Utility.convertESTToLocal(element.CreatedDate);
      });
      this.totalUserRecord = this.userActivityList?.length;
    },

    async postExpectionActivity(PageNumber, frequencyType) {
      this.pageCount = 0;
      this.totalUserRecord = 0;
      this.exceptionActivityList = [];
      if (frequencyType != 3) {
        this.endDate = "";
        this.startDate = "";
      }

      let obj = {
        UserId: this.userId,
        FrequencyType: frequencyType,
        StartDate: this.startDate,
        EndDate: this.endDate,
        PageNumber: PageNumber,
      };
      let trackerJSON = { trackerJSON: JSON.stringify(obj) };
      let getUserData = await activityLoggerService.postExceptionActivity("post", trackerJSON);
      this.exceptionActivityList = getUserData?.Resultset;
      if (this.exceptionActivityList.length > 0) {
        this.exceptionActivityList?.forEach((element) => {
          element.CreatedDate = Utility.convertESTToLocal(element.CreatedDate);
        });
        this.pageCount = getUserData.TotalPages;
        this.totalUserRecord = this.userActivityList?.length;
      } else {
        this.exceptionActivityList = [];
        this.pageCount = 0;
        this.totalUserRecord = 0;
      }
    },

    async postLoginActivity(PageNumber, frequencyType,graphChange) {
      this.pageCount = 0;
      this.totalUserRecord = 0;
      this.exceptionActivityList = [];
      let countData = [];
      let lableData = [];
      if (frequencyType != 3) {
        this.endDate = "";
        this.startDate = "";
      }

      let obj = {
        UserId: this.userId,
        FrequencyType: frequencyType,
        StartDate: this.startDate,
        EndDate: this.endDate,
        PageNumber: PageNumber,
        Pass: 1,
      };
      let trackerJSON = { trackerJSON: JSON.stringify(obj) };
      let getUserData = await activityLoggerService.postLoginActivity("post", trackerJSON);
      this.loginActivityList = getUserData?.Resultset;
      this.loginActivityListTemp=this.loginActivityList
      this.totalLoginRecords=this.loginActivityList.length
      if (this.loginActivityList.length > 0) {
        this.loginActivityList?.forEach((element) => {
          element.CreatedDate = Utility.convertESTToLocal(element.CreatedDate);
        });
        this.uniqueStatus = [...new Map(this.loginActivityListTemp.map((item) => [item["Success"], item.Success])).values()];
        this.uniqueEmail = [...new Map(this.loginActivityListTemp.map((item) => [item["Email"], item.Email])).values()];
        this.arrayData=[
          {id:1,label:"Status",array:this.uniqueStatus },
          {id:2,label:"Email",array:this.uniqueEmail },
        ]
        
        if(graphChange)
      {  let objGraph = {
          UserId: this.userId,
          FrequencyType: frequencyType,
          StartDate: this.startDate,
          EndDate: this.endDate,
          PageNumber: PageNumber,
          Pass: 2,
        };
        let trackerGraphJSON = { trackerJSON: JSON.stringify(objGraph) };
        let graphData = await activityLoggerService.postLoginActivity("post", trackerGraphJSON);
        let labels = [];
        let data = [];
        // graphData.Resultset.forEach((ele) => labels.unshift(ele.Date.trim()));
        // graphData.Resultset.forEach((ele) => data.unshift(ele.Count));
        graphData.Resultset = graphData.Resultset.reverse();
        graphData.Resultset.forEach((ele) => labels.push(ele.Date.trim()));
        graphData.Resultset.forEach((ele) => data.push(ele.Count));
        lableData = labels.slice(-15);
        this.chartData2.data.labels = lableData;
        countData = data.slice(-15);
        this.chartData2.data.datasets[0].data = countData;}
      } else {
        this.loginActivityList = [];
        this.pageCount = 0;
        this.totalUserRecord = 0;
      }
      if (this.chartInstance && this.chartInstance != null) {
        this.chartInstance.destroy();
      }

      this.ctxChart = document.getElementById("chart2");
      this.chartInstance = new Chart(this.ctxChart, this.chartData2);
    },

    filterMessage(page, frequencyType, startDate, endDate) {
      this.page = page;
      this.startDate = startDate;
      this.endDate = endDate;
      this.frequencyType = frequencyType;
      this.tempFrequencyType=frequencyType
      if (this.tabName == "user") this.postUserActivity(page, frequencyType);
      else if (this.tabName == "execption") this.postExpectionActivity(page, frequencyType);
      else if (this.tabName == "login") {
        this.showCanvas = false;
        this.postLoginActivity(page, frequencyType,true);
      }
    },
    pageChanged(page) {
      this.page = page;
      if (this.tabName == "user") this.postUserActivity(this.page, this.frequencyType);
      else if (this.tabName == "execption") this.postExpectionActivity(page, this.frequencyType);
      else if (this.tabName == "login") this.postLoginActivity(page, this.frequencyType,true);
    },

    onClickDetailsView(location, item) {
      this.showDialogDetailsBox = true;
      if (location == "exception_stack_trace") {
        this.dialogBoxHeader = "Show Stack Trace";
        this.displayMessage = item;
      } else if (location == " exception_description") {
        this.dialogBoxHeader = "Description";
        this.displayMessage = item;
      } else if (location == "login_SystemInfo") {
        this.dialogBoxHeader = "System Info";
        this.displayMessage = item;
      } else if (location == "login_errorDeatils") {
        this.dialogBoxHeader = "Error Details";
        this.displayMessage = item;
      }
    },
    clearFilter(){
     if(this.tempFrequencyType==0){
      this.frequencyType=0
      this.postLoginActivity(1,this.frequencyType,true)
     }
     else if(this.tempFrequencyType==1){
      this.frequencyType=1
      this.postLoginActivity(1,this.frequencyType,true)
     }
    },

    filteredValues(value, type) {
      this.searchUser="";
      this.searchActivity="";
      let filters={}
      if(this.tabName=="login"){
       filters = {
        0: () => this.loginActivityListTemp,
        1: (value) => this.loginActivityListTemp.filter(element => element.Success === value),
        2: (value) => this.loginActivityListTemp.filter(element => element.Email === value),
      };}
      else{
        filters = {
          0: () => this.userActivityListTemp,
          1: (value) => this.userActivityListTemp.filter(element => element.name === value),
          2: (value) => this.userActivityListTemp.filter(element => element.action === value),
      }  
    }
    this.filterValueUpdate(filters[type](value));
  },
    filterValueUpdate(data) { 
      if(this.tabName=="login"){
      this.loginActivityList = data;
      this.totalLoginRecords = this.loginActivityList.length;
      }
      else{
        this.userActivityList = data;
        this.totalUserRecord = this.userActivityList.length;
      }
    },
  },

  components: {
    breadcrumbComp,
    FilterComp,
    FilterTableComp
  },
};
